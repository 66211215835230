import React from "react"
import {graphql} from 'gatsby';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from '../components/Layout'
import * as styles from '../styles/home.module.css'

export default function Home() {
  const { t, i18n } = useTranslation();
  // i18n.init(GATSBY_CONFIG);
  // i18n.changeLanguage('fr');

  return (
    <Layout>
      <section className={styles.headerHome}>
        <div className={styles.text}>
          <h1>Aija Dumceva</h1>
          <h3><Trans>Chef de projet junior</Trans></h3>
          <p><Trans>Motivée • Créative • Passionnée</Trans></p>
        </div>
        {/*<p>{t('Qui suis-je?')}</p>*/}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
  `;
